<template>
  <v-main>
    <v-layout align-center justify-center style="border-radius:20px">
      <v-dialog persistent content-class="vdialogloginnew" v-model="show" width="800" style="position:absolute;">
          <v-card class="primary--text">
              <v-row>
                  <v-flex xs12 md5 class="main-row">
                      <div class="row-logo-img">
                          <div class="ml-10 mt-10 mb-10">
                              <v-img src="@/assets/img/full_logo.png" width="200"></v-img>
                              <div class="footer-login">
                                  <h3 class="title-login mb-8">Quick Count</h3>
                                  <p class="mb-1">{{footer.webVersion}}</p>
                                  <p>{{footer.apiVersion}}</p>
                              </div>
                          </div>
                      </div>
                  </v-flex>
                  <v-flex xs12 md7>
                      <v-row class="mt-5 mr-5">
                          <v-col class="md-12"></v-col>
                      </v-row>
                      <div class="row-form">
                          <v-row>
                              <v-col class="md-12">
                              <h3>Login</h3>
                              <small class="">Please login to access feature admin</small>
                              <div class="col-line">
                                  <v-row>
                                      <v-col class="md-12">
                                          <label class="label-username">Username</label><b> *</b>
                                          <v-text-field
                                              v-model="body.form.username"
                                              :rules="[rules.required]"
                                              placeholder="Masukan username anda disini"
                                              outlined
                                              class="pt-1"
                                          ></v-text-field>
                                      </v-col>
                                  
                                  </v-row>
                                  <v-row>
                                      <v-col class="md-12">
                                          <label class="label-password">Password</label><b> *</b>
                                          <v-text-field
                                              v-model="body.form.password"
                                              :rules="[rules.required]"
                                              placeholder="Masukan kata sandi anda disini"
                                              outlined
                                              class="pt-1"
                                              :append-icon="body.form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                              :type="body.form.showPassword ? 'text' : 'password'"
                                              @click:append="btnShowPassword"
                                          ></v-text-field>
                                      </v-col>
                                  </v-row>
                              </div>
                              <v-card-actions class="px-0 py-0">
                                  <v-btn class="btn-login-new" @click="btnLogin" :loading="body.loading" :disabled="body.loading">
                                      Login
                                      <template v-slot:loader>
                                          <span class="custom-loader">
                                              Please wait...
                                          </span>
                                      </template>
                                  </v-btn>
                              </v-card-actions>
                              
                          </v-col>
                          </v-row>
                      </div>
                  </v-flex>
                  <core-alert class="mt-2" :flagShow="body.alert.show" :txtType="body.alert.type" :txtMsgAlert="body.alert.message"></core-alert>
              </v-row>
          </v-card>
      </v-dialog>
      
    </v-layout>
  </v-main>             
</template>
<script>
import {isValidForm} from '@/consistencychecker/formvalidator/index'
import { getAPIVersion} from '@/consistencychecker/transactionvalidator/login'
// trx validasi yang bersifat global
import {success, error} from '@/consistencychecker/transactionvalidator/index'
import {setCookie,getCookie} from '@/plugins/cookie'

export default {
  metaInfo () {
  return { title: 'Quick Count' }
  },
  name: 'Main',
  props: {
      value: {
          type: Boolean,
          default: true
      },
  },
  data (){
      return {
          serviceID: `${process.env.VUE_APP_SERVICE_ID}`,
          header:{
              title:"Change Endpoint Gateway",
          },
          footer:{
              webVersion:`WEB-V${process.env.VUE_APP_VERSION_WEB}`,
              apiVersion:`API-V${process.env.VUE_APP_VERSION_API}`,
          },
          body: {
              loader: null,
              loading: false,
              urlGW: process.env.VUE_APP_END_GW, 
              tokenGW: process.env.VUE_APP_TOKEN_GW,
              form : {
                  username: '',
                  password: '',
                  showPassword: false,
                  showType: 'password', 
                  infoLogin: '',
                  passwordLabel: 'Password'
              },
              alert : {
                  show: false,
                  type: 'error',
                  message: ''
              },
              dialog: {
                  show: false,
                  message: {
                      shown: false,
                      txtTittle: "Message",
                      txtBody: "",
                      flagBtn1: true,
                      txtBtn1: "OK",
                      flagBtn2: false,
                      flagBody: true
                  }
              },
          },
          model: '',
          max: '',
          rules: {
              required: value => !!value || 'Tidak boleh kosong.',
              emailMatch: () => (`The email and password you entered don't match`),
          },
      }
  },
  async created(){
    this.footer.apiVersion = await getAPIVersion()
  },
  beforeMount() {
    if(!getCookie('endpointGW') || getCookie('endpointGW')=="") {
      setCookie('endpointGW',this.body.urlGW)
    }
    if(!getCookie('tokenGW') || getCookie('tokenGW')=="") {
      setCookie('tokenGW',this.body.tokenGW)
    }
  },
  mounted(){
      if(getCookie('endpointGW') && getCookie('endpointGW')!='') this.body.urlGW=getCookie('endpointGW')
      if(getCookie('tokenGW') && getCookie('tokenGW')!='') this.body.tokenGW=getCookie('tokenGW')
      this.syncSession()
      setTimeout(()=>{this.body.loading=false},1000)
  },
  computed: {
      isLoggedIn () {
          return this.$store.getters['auth/isSessionActive']
      },
      show: {
          get () {
              return this.value
          },
          set (value) {
              this.$emit('input', value)
          }
      }
  },
  watch: {
      loader () {
        const l = this.body.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.body.loader = null
      },
  },
  methods: {
      handleSuccess(data){
        this.body.alert.show = true
        this.body.alert.type = this.$store.getters['app/color']('s')
        this.body.alert.message = success(data)
        setTimeout(() => { this.body.loading=false },1000)
        setTimeout(() => { this.body.alert.show=false },3000)
      },
      handleError(err){
        this.body.alert.show = true
        this.body.alert.type = this.$store.getters['app/color']('e')
        this.body.alert.message = error(err)
        setTimeout(() => { this.body.loading=false },1000)
        setTimeout(() => { this.body.alert.show =false },3000)
      },
      syncSession(){
        console.log('sys',this.$store.getters['auth/isSessionActive'])
        if (this.$store.getters['auth/isSessionActive']) {
          this.$router.push('/home')
        }
      },
      btnShowPassword() {
        this.body.form.showPassword = !this.body.form.showPassword
      },
      async btnLogin() {
          try {
            let self = this
            this.body.loading = true
            let data = {username: this.body.form.username,password: this.body.form.password}
            let validEntry = isValidForm(data)
            if(validEntry.status === false) return this.handleError(validEntry.message)
            await this.$store.dispatch('auth/logIn', data ).then(res => {
              console.log('res',res)
              if(res.status) {
                setInterval(()=>{ self.$router.go('/home') }, 300)
              } else {
                this.handleError(res.message)
              }
            }).catch(err=>err)
          } catch(e){
            this.body.loading=false
            this.body.dialog.message.shown = true
            this.body.dialog.message.txtBody = e.message
          }
      },
  }
}
</script>
<style>
/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
  display: none;
}
/* style the overlay container as required */
.v-overlay--active {
  backdrop-filter: blur(2px);
  background: rgb(54 54 54 / 1);
}
/* if you have an auto dark theme
 for prefers-color-scheme: dark
 I find the 0.8 too dark
*/
@media (prefers-color-scheme: dark) {
  .v-overlay--active {
      background: rgb(54, 54, 54, 1);
  }
}

.main-row {
  background: linear-gradient(160.34deg, #C91C22 2.12%, #9F0006 99.2%);
}
.row-logo-img {
  background-image: url('../../assets/img/login-img.png');
  height: 410px;
}

.footer-login {
  position: absolute;
  bottom: 20px;
}

.title-login {
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 27px;
}

.vdialogloginnew {
  border-radius: 20px;
}

.footer-login p {
  color: white;
  font-size: 12px;
}

.row-form {
  padding: 0px 40px;
}

.row-form h3 {
  font-size: 24px;
  color: #414042;
}
.row-form small {
  font-size: 14px;
  color: #868D97;
}

.label-username, .label-password {
  color: #414042;
  font-size: 16px;
}

.row-form .v-text-field--outlined > .v-input__control > .v-input__slot{
min-height: 45px;
}

.row-form .col-line {
  padding: 50px 0 20px 0;
}

.row-form .v-icon {
  bottom: 5px;
  cursor: pointer;
}

.row-form .v-icon-reset {
  bottom: 0px;
}

.line-forgot-password {
  position: relative;
  bottom: 40px;
  text-align: right;
}

.btn-login-new {
  width: 100%;
  background: #C91C22 !important;
  border-radius: 100px;
  color: white !important;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.v-btn {
  text-transform: capitalize;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

</style>
