import { message } from './message'

// Entry form
export function isValidForm(objForm) {
    let err = [];
    let result = { status: false, message: message.error[100]() }
    let obj = Object.keys(objForm)
    for (let i = 0; i < obj.length; i++) {
        let prop = obj[i]
        let val = objForm[obj[i]]
        if (Array.isArray(val) && val.length > 0 && typeof val[0] === 'object') {
            let subErr = []
            for (let j = 0; j < val.length; j++) {
                _.forEach(val[j], (subVal, subKey) => {
                    if (!isValidValue(subVal)) {
                        subErr.push({ index: j, field: subKey, message: message.error[100]() })
                    }
                })
                if (subErr.length > 0) {
                    err.push({ field: prop, message: subErr })
                }
            }
        } else if (!isValidValue(val)) {
            err.push({ field: prop, message: message.error[100]() })
        } else if (prop=='sKtpNo'){
            if(val.match(/^[0-9]+$/) == null || val.length!=16){
                err.push({ field: prop, message: message.error[100]() })
            }
        }
    }
    if (err.length == 0) {
        result.status = true
        result.message = message.success[2]()
    } else {
        result.errors = err
        result.message = err.map(x => (Array.isArray(x.message) ? message.error[100](x.field) : x.message)).join('\n')
    }
    return result
}
function isValidValue(val) {
    if (Array.isArray(val) && val.length == 0)
        return false
    else if (val==='' || val===' ' || val===null)
        return false
    return true
}
// additional checker untuk spesifik field tertentu
export function objIsNotEmpty(obj) {
    for (let key in obj) {
        if (!obj.hasOwnProperty(key) || obj !== null) {
            return true
        }
    }
    return false
}
export function stringIsEmpty(inp) {
    return inp === null || inp === undefined || inp === '' || inp === ' '
}
export function arrayAddUniq(target, source) {
    source.forEach(v => {
        let p = target.indexOf(v);
        if (p === -1) target.push(v);
        else target.splice(p, 1);
    });
    return target;
}
export function isExceedMaxDataRow(data, maxDataRow) {
    let result = { status: (data.length <= maxDataRow), message: message.success[1]() }
    if (result.status === false) result.message = message.error[101](5000)
    return result;
}
export function isValidDataRow(data, msg) {
    let result = { status: (data.length>0), message: message.success[1]() }
    if (result.status === false) result.message = msg
    return result;
}
export function isNotEqualParam(rdata, field, op, param) {
    let err=[]
    let result = { status: false, message: message.error[1]() }
    let errCode=op=='eq'?124:125
    if(!rdata[field].toUpperCase().includes(param.toUpperCase())){
        err.push(message.error[errCode](param))
    }
    if(rdata[field].toUpperCase()==param.toUpperCase()){
        err.push(message.error[errCode](param))  
    }
    result.status = err.length==0
    result.message = err
    return result;
}
export function isNotEmpty(data){
    return data !== null && data !== '' && data !== ' '
}