import {message} from './message'

const success = (data) =>{
    return typeof(data)==='string'?data :data&&data.message?message.success[0](data.message):message.success[200]()
}
const error = (err) => {
    return typeof(err)==='string'?err:!navigator.onLine ? message.error[598]():
    (err&&err.response&&err.response.data) ? err.response.data.message:
    (err&&err.response&&err.respons.messagee) ? err.response.message:
    (err&&err.message&&err.message.includes('status code 400')) ? message.error[400]():
    (err&&err.message&&err.message.includes('status code 401')) ? message.error[401]():
    (err&&err.message&&err.message.includes('status code 404')) ? message.error[404]():
    (err&&err.message&&err.message.includes('status code 405')) ? message.error[405]():
    (err&&err.message&&err.message.includes('status code 406')) ? message.error[406]():
    (err&&err.message&&err.message.includes('status code 408')) ? message.error[407]():
    (err&&err.message&&err.message.includes('status code 409')) ? message.error[409]():
    (err&&err.message&&err.message.includes('status code 411')) ? message.error[411]():
    (err&&err.message&&err.message.includes('status code 417')) ? message.error[417]():
    (err&&err.message&&err.message.includes('status code 524')) ? message.error[524]():
    (err&&err.message&&err.message.includes('Network Error')) ? message.error[408](): message.error[407]()
}

export {success, error}