const message = {
  type: `transaction`,
  success: {
    0:(p)=> p,
    200:()=> `Sukses`
  },
  error: {
    0:(p)=> p,
    400:()=> `Ditolak. Terjadi kesalahan coding pada endpoint private service`, // Bad Request
    401:()=> `Ditolak. Session expired`, // Unauthorized
    402:()=> `Ditolak. Session tidak sesuai`, // Unauthorized
    404:()=> `Ditolak. Endpoint tidak ditemukan`, // Not Found
    405:()=> `Ditolak. Method tidak ditemukan`, // Method Not Allowed
    406:()=> `Ditolak. Param Header tidak lengkap`, // Not Acceptable
    407:()=> `Ditolak. Terjadi kesalahan pada codingan WEB`, // error web
    408:()=> `Ditolak. Request Timeout`, // request timeout pada endpoint private service
    409:()=> `Ditolak. Terjadi kesalahan query transaksi ke database`, // Conflict
    411:()=> `Ditolak. Data tidak lengkap`, // consystence checker
    417:()=> `Ditolak. Data tidak valid`, // Expectation Failed
    524:()=> `Ditolak. Data Timeout`, // Data timeout pada query
    598:()=> `Ditolak. Jaringan internet tidak tersedia`, // Network read timeout error
  }
}

export {message}